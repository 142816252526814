@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";


body { font-family: 'Roboto', sans-serif; font-weight: 400; }
* { outline: 0; }
img { max-width: 100%; }

main { margin-bottom: 50px; }

.btn, .form-control, .custom-radio.rounded span:before, .custom-radio.rounded span:after, .custom-radio.square span:before, .custom-radio.square span:after, .custom-switch .slider, .custom-switch .slider:before, .tile-btn { transition: all 0.3s; -webkit-transition: all 0.3s; -moz-transition: all 0.3s; -ms-transition: all 0.3s; -o-transition: all 0.3s; }

#MainDiv { padding: 0 16px 16px; max-width: 650px; margin: 35px auto; box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1); overflow: auto ;border-radius: 22px;    min-height: 300px;}
.modal-open #MainDiv, .modal-open main { filter: blur(5px); }
.styleguide-icon-btn { display: flex; }
.styleguide-icon-btn .btn + .btn { margin-left: 5px; }
.purple-bg { background-color: #7C5B8C; padding: 16px; margin: 0 -16px; }


/* Main title */
.page-title { margin: 0 -16px 77px; padding: 30px 16px 50px 16px; color: #fff; display: flex; align-items: flex-start; position: relative; z-index: 1; overflow: hidden; }
.page-title:before { content: ''; width: 130%; height: 125%; background: #5F60A9; background: linear-gradient(90.19deg, #532C6D 0.04%, #64407B 99.95%); border-radius: 50%; position: absolute; left: -15%; right: 0; bottom: 0; z-index: -1; }
.page-title.lg { padding: 37px 23px 34px; margin-bottom: 51px; }
.page-title.lg:before { width: 140%; left: -20%; }
.page-title .go-back { background: transparent; border: 0; padding: 0; cursor: pointer; outline: 0; padding: 3px 0; margin-right: 14px; }
.page-title .go-back:hover { opacity: 0.7; }
.page-title h1 { margin: 0; font-size: 28px; line-height: 34px; font-family: 'Poppins', sans-serif; font-weight: 600; }
.page-title p { font-family: 'Poppins', sans-serif; font-weight: 600; font-size: 20px; line-height: 24px; margin: 0; }


.form-group { margin-bottom: 30px; }
.form-group label { font-size: 16px; line-height: 20px; margin-bottom: 0; display: flex; justify-content: space-between; color: #000; }
.form-group.custom-radio-wrapper label { margin-bottom: 8px; }
.form-group label .optional { opacity: 0.5; }


/* Custom radio-checkbox */
.custom-radio { display: inline-flex; align-items: center; position: relative; }
.custom-radio + .custom-radio { margin-left: 20px; margin-bottom: 9px; }
.custom-radio .custom-input { position: absolute; left: 0; right: 0; top: 0; bottom: 0; height: 100%; width: 100%; z-index: 1; cursor: pointer; opacity: 0; }
.custom-radio .custom-input:checked + span { color: #fff; background-color: #5E3976; }
.custom-radio span { font-size: 16px; line-height: 19px; font-weight: 700; color: #5E3976; border: 1px solid #5E3976; border-radius: 12px; padding: 9px 11px; position: relative; }
.custom-radio.secondary span { padding: 0 9px; border-radius: 50%; width: auto; max-width: 26px; height: 26px; display: flex; align-items: center; justify-content: center; font-weight: 400; border: 0; }
/*.custom-radio.secondary span {
	padding: 3px 4px;
	border-radius: 50%;
	width: auto;
	max-width: 25px;
	min-width: 25px;
	font-weight: 400;
	border: 0;
	display: block;
	text-align: center;
	}*/
	.custom-radio.secondary + .custom-radio.secondary { margin-left: 20px; }
	.radio-indicators { display: flex; justify-content: space-between; margin-top: 5px; max-width: 482px; }
	.radio-indicators span { color: #8E8E93; font-size: 14px; line-height: 16px; width: 30%; }
	.radio-indicators span:last-child { text-align: right; }

	.custom-radio.rounded span { padding: 0; min-height: 20px; min-width: 20px; border: none; color: #000; padding-left: 25px; font-weight: 400; }
	.custom-radio.rounded span:before { content: ''; position: absolute; height: 20px; width: 20px; background-color: transparent; border-radius: 50%; left: 0; border: 2px solid #373688; }
	.custom-radio.rounded span:after { content: ''; position: absolute; height: 8px; width: 8px; background-color: #fff; transform: scale(0); border-radius: 50%; left: 6px; top: 6px; }
	.custom-radio.rounded .custom-input:checked + span { color: #000; background-color: transparent; border-color: transparent; }
	.custom-radio.rounded .custom-input:checked + span:before { background-color: #373688; }
	.custom-radio.rounded .custom-input:checked + span:after { transform: scale(1); }


	.custom-radio.square span { padding: 0; min-height: 18px; min-width: 18px; border: none; color: #000; padding-left: 25px; font-weight: 400; }
	.custom-radio.square span:before { content: ''; position: absolute; height: 18px; width: 18px; background-color: transparent; border-radius: 3px; left: 0; border: 2px solid #373688; }
	.custom-radio.square span:after { content: ''; position: absolute; height: 9px; width: 12px; background-image: url(./images/tick.svg); background-repeat: no-repeat; background-size: 12px; transform: scale(0); border-radius: 3px; left: 3px; top: 4px; }
	.custom-radio.square .custom-input:checked + span, .custom-radio.square .custom-input:indeterminate + span { color: #000; background-color: transparent; border-color: transparent; }
	.custom-radio.square .custom-input:checked + span:before, .custom-radio.square .custom-input:indeterminate + span:before { background-color: #373688; }
	.custom-radio.square .custom-input:checked + span:after { transform: scale(1); }
	.custom-radio.square .custom-input:indeterminate + span:after { background-image: none; width: 10px; height: 2px; background-color: #fff; transform: scale(1); top: 8px; left: 4px; }



	/* Switch */
	.custom-switch { position: relative; display: inline-block; line-height: 20px; min-height: 18px; }
	.custom-switch input { opacity: 0; width: 100%; height: 100%; position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: 1; cursor: pointer; }
	.custom-switch .slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #DCDCDC; border-radius: 36px; border: 1px solid #CACACA; width: 33px; height: 18px; }
	.custom-switch .slider:before { position: absolute; content: ""; height: 14px; width: 14px; left: 1px; top: 1px; background-color: #fff; border-radius: 50%; }
	.custom-switch input:checked + .slider { background-color: #373688; }
	.custom-switch input:checked + .slider:before { -webkit-transform: translateX(15px); -ms-transform: translateX(15px); -moz-transform: translateX(15px); -o-transform: translateX(15px); transform: translateX(15px); }


	/* Input & Dropdowns styling */
	.form-control { padding: 7px 12px; border-radius: 8px; border: 1px solid #c4c4c4; color: #000; height: 40px;}
	.form-control:focus { color: #000; border-color: #5F60A9; outline: 0; box-shadow: none; }
	textarea.form-control { height: auto; min-height: 100px; }
	.form-control.custom-dropdown { -webkit-appearance: none; background: url('./images/dropdown-arrow.svg'); background-repeat: no-repeat; background-position: calc(100% - 16px) center; padding-right: 40px; }


	.btn-block.prev-back-btn { display: flex; margin-top: 33px; }
/*.btn-block.prev-back-btn .btn-outline-primary { width: calc(36% - 8px); }
.btn-block.prev-back-btn .btn-primary { width: calc(64% - 8px); }*/
.btn-block.prev-back-btn .btn { min-width: 172px; }
.btn-block.prev-back-btn .btn + .btn { margin-left: 16px; }


/*Buttons*/
/* .btn { border-radius: 12px; font-size: 16px; line-height: 20px; font-weight: 700; padding: 9px 18px; border: 3px solid transparent; position: relative; z-index: 1; -webkit-transform: perspective(1px) translateZ(0); transform: perspective(1px) translateZ(0); } */
.btn { height: 40px; border-radius: 12px; font-size: 16px; line-height: 20px; font-weight: 700; padding-top: 8px; padding-bottom: 6px; padding-left: 16px;padding-right: 16px; border: 3px solid transparent; position: relative; z-index: 1; -webkit-transform: perspective(1px) translateZ(0); transform: perspective(1px) translateZ(0); }
/* .btn:after { content: ''; position: absolute; z-index: -1; border-radius: 6px; top: 0; left: 0; right: 0; bottom: 0; -webkit-transform: scaleX(0); transform: scaleX(0); -webkit-transform-origin: 0 50%; transform-origin: 0 50%; -webkit-transition-property: transform; transition-property: transform; -webkit-transition-duration: 0.3s; transition-duration: 0.3s; -webkit-transition-timing-function: ease-out; transition-timing-function: ease-out; } */
/* .btn:hover:after, .btn:focus:after, .btn:active:after { -webkit-transform: scaleX(1); transform: scaleX(1); } */
/* .btn:focus { box-shadow: none !important; } */
.btn.disabled, .btn:disabled { opacity: .50; }
.btn.disabled:after, .btn:disabled:after { display: none; }


/* Primary button */
.btn-primary { background-color: #FF6B38 ; border: 0; overflow: hidden; }
.btn-primary:after { background: #fd7345 ; }
.btn-primary:hover, .btn-primary:focus { color: #fff; background-color: #E65526; border-color: #E65526; }
/* .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle { color: #fff; background-color: #E65526; border-color: #E65526; } */
/* .btn-primary.disabled, .btn-primary:disabled { color: #fff; background-color: #E65526; border-color: #E65526; } */


/* Secondary/outline button */
.btn-outline-primary { color: #FF6B38 ; border-color: #FF6B38 ; background-color: #fff}
.btn-outline-primary:after { background: #FF6B38 ; }
.btn-outline-primary:hover, .btn-outline-primary:focus { color: #E65526; background-color: #EFEFEF ; border-color: #E65526 ; }
/* .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle { color: #fff; background-color: transparent; border-color: #E65526; } */
.btn-outline-primary.disabled, .btn-outline-primary:disabled { color: #FF6B38; border-color: #FF6B38; background-color: transparent; }


/* Light button */
.btn-light { color: #7C5B8C; background-color: #fff; border: 0; overflow: hidden; }
.btn-light:after { background: #e2e6ea; }
.btn-light:hover, .btn-light:focus { color: #7C5B8C; background-color: #fff; border-color: #e2e6ea; }
.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle { color: #7C5B8C; background-color: #fff; border-color: #e2e6ea; }
.btn-light.disabled, .btn-light:disabled { color: #7C5B8C; border-color: #fff; background-color: #fff; }

/* Light outline button */
.btn-outline-light { color: #fff; border-color: #fff; }
.btn-outline-light:after { background: #fff; }
.btn-outline-light:hover, .btn-outline-light:focus { color: #7C5B8C; background-color: transparent; border-color: #fff; }
.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show>.btn-outline-light.dropdown-toggle { color: #7C5B8C; background-color: transparent; border-color: #fff; }
.btn-outline-light.disabled, .btn-outline-light:disabled { color: #fff; border-color: #fff; background-color: transparent; }

/* Icon button */
.btn.icon-btn { height: 28px; width: 28px; padding: 0; border-radius: 50%; display: flex; align-items: center; justify-content: center; cursor: pointer; }
.btn.icon-btn:after { background-color: #DEEFF9; transform: scale(0); transform-origin: center; -webkit-transform-origin: center; border-radius: 50%; }
.btn.icon-btn:hover:after, .btn.icon-btn:focus:after { transform: scale(1); }

.btn-link { color: #373688; border-radius: 12px; padding: 3px 5px; text-decoration: none; overflow: hidden; }
.btn-link:after { background-color: #DEEFF9; }
.btn-link:hover, .btn-link:focus { text-decoration: none; }


/* Modal */
.modal.show { display: block; }
.modal-backdrop { display: none; }
.modal-backdrop.show { display: block; }
.modal-body { min-height: 310px; position: relative; padding: 24px 16px 16px; }
.modal-close { position: absolute; right: 11px; top: 11px; }
.modal-content { border-radius: 12px; }
.modal h2 { font-family: 'Poppins', sans-serif; font-weight: 600; font-size: 20px; line-height: 26px; color: #373688; margin-bottom: 15px; }




/* Tiles */
.tile { padding: 4px 10px; display: block; max-width: 100%; border-radius: 11px; position: relative; z-index: 1; color: #fff; }
.tile * { color: #fff; }
.tile h2 { font-family: 'Poppins', sans-serif; font-weight: 600; font-size: 18px; line-height: 20px; }
.tile h3 { font-size: 16px; line-height: 20px; font-weight: 700; margin-bottom: 3px; }


.tile.type1 { width: 337px; min-height: 212px; background: linear-gradient(79.86deg, #00B7FB 3.18%, #B3EAFF 91.27%); box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25); }
.tile.type1:after { content: ''; position: absolute; left: 0; right: 0; top: 0; bottom: 0; height: 100%; width: 100%; background-image: url(./images/character.svg); background-size: contain; background-repeat: no-repeat; background-position: calc(100% - 9px) calc(100% - 7px); z-index: -1; }
.tile.type1 .tile-content { max-width: 110px; }
.tile.type1 .btns { display: flex; margin-bottom: 7px; }
.tile.type1 .tile-btn { background: transparent; border: 0; padding: 0; cursor: pointer; line-height: 0;outline: none; }
.tile.type1 .tile-btn:hover { opacity: 0.7;  }
.tile.type1 .tile-btn + .tile-btn { margin-left: 6px; }
.tile.type1 p { font-size: 14px; line-height: 16px; }


.tile.type2 { background: linear-gradient(119.88deg, #5F60A9 3.87%, rgba(95, 96, 169, 0.72) 128.77%); box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); width: 344px; }
.tile.type2 h2 { display: flex; justify-content: space-between; margin: 2px 0 4px; }
.tile.type2 h2 .chat-img { margin: 3px 25px 0 0; }
.tile .chatbot { display: flex; margin-bottom: 9px; }
.tile .chatbot .dp { width: 47px; min-width: 47px; }
.tile .chatbot .text { padding-left: 0; width: calc(100% - 47px); }
.tile .chatbot .text p { font-weight: 700; font-size: 16px; line-height: 20px; margin: 0; }


.tile.type3 { padding: 8px 10px 45px; width: 165px; background: linear-gradient(103.41deg, #5F60A9 -102.56%, #70BBE6 177.56%); box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); }
.tile.type3:after { content: ''; position: absolute; left: 0; right: 0; top: 0; bottom: 0; height: 100%; width: 100%; background-image: url(./images/docs.svg); background-size: 69px; background-repeat: no-repeat; background-position: calc(100% - 10px) calc(100% + 1px); z-index: -1; }


.tile.type4 { padding: 34px 17px 30px; background: #fff; border-radius: 0; width: 375px; }
.tile.type4 .chat-outer { margin-bottom: 15px; }
.tile.type4 .chat-outer .date { font-size: 14px; line-height: 16px; color: #562A6B; display: block; padding-left: 6px; margin-bottom: 4px; }
.tile.type4 .chat-outer p { background: #E6E6E6; border-radius: 11px; padding: 7px 12px 7px 6px; font-size: 16px; line-height: 20px; color: #562A6B; display: inline-block; margin: 0; }
.tile.type4 .chat-outer.reverse { text-align: right; }
.tile.type4 .chat-outer.reverse .date { padding: 0 3px 0 0; }
.tile.type4 .chat-outer.reverse p { background: #5F60A9; color: #fff; text-align: left; padding: 7px 6px 7px 9px; max-width: 78%; }

.has-form { max-width: 360px; margin: 0 auto; }
.has-form-forms {margin-left: 60px; margin-right: 60px;}

/* 
@media(max-width: 1199px) {
	.container { max-width: 100%; }
}

@media(max-width: 767px) {
	.form-group { margin-bottom: 25px; }
	.custom-radio + .custom-radio { margin-left: 2px; }
}
 */

 .headlinetwo{
	/* font-family: Poppins; */
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
 }



.abc{
	/* padding-left: 7px; */
	font-weight: 100;
	margin-top: 10px;
	font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    color: #000;
  }

  .errorMessage{
    color: #D8000C;
    font-size: smaller;
    padding-left: 9px;
}

.create{
    font-size: 16px;
	padding-right: 50px;
	margin-left: 90px;
}

.HomeList{
    font-size: 16px;
	padding-right: 50px;
}

.logout {
	display: block;
    /* width: 131px; */
    background-color: #FF6B38;
	border-radius: 12px;
    height: 33px;
    font-size: 16px;
    color: white;
	font-weight: 700;
	border-color: #ff6b38;
	box-shadow: none !important;
	border: aliceblue;
	margin-top: 19px; 
	margin-right : 34px;
}
.logout:hover{

	
 color: #fff; 
 background-color: #E65526; 
 transition: 0.3s;
 border-color: #E65526;

}

#tooltip {
	position: absolute;
	color: rgb(15, 14, 14);
	padding: 10px;
	background:rgb(211,211,211);
	border-radius: 2px;
	z-index: 1000;
  }

  .contentSpacing {
height: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  


.bodymap{
	padding: 0%;
	
}

.fullbodyfront{
	padding-left: 39%;
	background-image:url('./body-front/body-front.png');
	width: 600px;
	height: 1000px;
}

.fullbodyback{
	padding-left: 39%;
	background-image:url('./body-back/body-back.png');
	width: 600px;
	height: 1000px;
}

.jaw-front{margin-left: 24px;margin-top: 1px;float: left;}

.cervical-front{margin-left: -125px;margin-top: 41px;float: left;}

.b-cervical{margin-left: 24px;}


.right-shoulder-front{float: left;margin-top: 87px;margin-left: -221px;}

.right-chest-front{float: left;margin: 87px -173px;}

.left-chest-front{float: left;margin: 86px -90px;}

.left-shoulder-front{float: left;margin: 86px -8px;}

.left-shoulder-back{margin: 3px 2px;float: left;}

.left-thoracic{float: left;margin: 3px -2px;}

.right-thoracic{float: left;margin: 3px 2px;}

.right-shoulder-back{float: left;margin: 3px -2px;}

.belowhead{padding: 0%;}

.ArmRibsRegion{padding: 0%;margin-left: -76px;}

.left-upper-arm-back{float: left;margin: 83px -264px;}

.left-ribs-back{margin: 83px -211px;}

.central-thoracic{margin: 83px -154px;}

.right-ribs-back{margin: 83px -102px;}

.right-upper-arm-back{margin: 83px -45px;}

.right-upper-arm-front{float: left;margin-left: -255px;margin-top: 152px;}

.right-ribs-front{margin-left: -203px;margin-top: 185px;}

.left-ribs-front{float: left;margin-left: -121px;margin-top: 185px;}

.left-upper-arm-front {float: left;margin-left: -38px;margin-top: 147px;}

.right-elbow-front{margin-top: 231px;margin-left: -296px;}

.abdomen-front{margin: 217px -211px;}

.left-elbow-front{float: left;margin: 224px -51px;}

.left-elbow-back{float: left;margin-left: -287px;margin-top: 161px;}

.thoracolumbar{float: left;margin: 161px -213px;}

.right-elbow-back{margin: 161px -45px;}

.right-forearm-front{float: left;margin: 289px -310px;}

.left-forearm-front{float: left;margin-left: 161px;margin-top: 49px;}

.left-forearm-back{float: left;margin-top: 214px;margin-left: -303px;}

.left-lumbosacral{margin-top: 214px;margin-left: -215px;}

.right-lumbosacral{float: left;margin: 214px -130px;}

.right-forearm-back{margin-top: 214px;margin-left: -29px;}

.right-wrist-front{margin-top: 134px;margin-left: -376px;}

.right-pelvis-front{margin-left: -263px;margin-top: 91px;}

.left-pelvis-front{float: left;margin-left: 60px;margin-top: -43px;}

.left-wrist-front{float: left;margin-left: 198px;margin-top: -19px;}

.left-wrist-back{float: left;margin: 300px -378px;}

.left-pelvis-back{margin: 268px -263px;}

.right-pelvis-back{float: left;margin: -32px 60px;}

.right-wrist-back{float: right;margin-right: 93px;margin-top: 0px;}

.right-thumb-front {float: left;margin: 18px -419px;}

.right-hand-front{float: left;margin-left: -406px;margin-top: 19px;}

.right-hip-front{float: left;margin: 1px -294px;}

.left-hip-front{margin-left: 60px;float: left;margin-top: -17px;}

.left-hand-front{float: right;margin-right: 94px;margin-top: 0px;}

.left-thumb-front{float: right;margin-right: -73px;margin-top: 0px;}

.left-thumb-back{float: left;margin: 37px -158px;}

.left-hand-back{float: left;margin-left: -126px;margin-top: 37px;}

.left-hip-back{float: left;margin: 20px -33px;}

.right-hip-back{margin-left: 33px;float: left;margin-top: 20px;}

.right-hand-back{float: right;margin-right: 113px;margin-top: 0px;}

.right-thumb-back{float: right;margin-right: -70px;margin-top: 0px;}

.fingerUppperleg{margin-left: -76px;}

.right-fingers-front{float: left;margin: 35px -308px;}

.right-upper-leg-front{margin: 1px 32px;float: left;}

.left-upper-leg-front{margin: 1px -32px;float: left;}

.left-fingers-front{float: right;margin: -19px 85px;}

.left-fingers-back{float: left;margin: 32px -309px;}

.left-upper-leg-back{float: left;margin: 19px -197px;}

.right-upper-leg-back{margin: 19px -93px;float: left;}

.right-fingers-back{float: right;margin-right: -68px;margin-top: 32px;}

.right-knee-front{float: left;margin: 120px -158px;}

.left-knee-front{float: left;margin: 120px -56px;}

.left-knee-back{float: left;margin: 186px -178px;}

.right-knee-back{float: left;margin: 186px -75px;}

.right-lower-leg-back{float: left;margin: 258px -75px;}

.left-lower-leg-back{float: left;margin: 258px -182px;}

.left-ankle-back{float: left;margin: 431px -154px;margin-bottom: 0px;}

.right-ankle-back{float: left;margin: 431px -71px;margin-bottom: 0px;}

.left-foot-back{float: left;margin: 475px -153px;margin-bottom: 0px;}

.right-foot-back{float: left;margin: 475px -71px;margin-bottom: 0px;}

.left-toe-back{float: left;margin: 475px -178px;margin-bottom: 0px;}

.right-toe-back{float: left;margin: 475px -33px;margin-bottom: 0px;}

.right-lower-leg-front{float: left;margin: 212px -160px;}

.left-lower-leg-front{float: left;margin: 214px -52px;}

.right-ankle-front{float: left;margin: 373px -134px;margin-bottom: unset ;}

.left-ankle-front{float: left;margin: 373px -71px;margin-bottom: unset ;}

.right-foot-front{float: left;margin: 421px -138px;margin-bottom: unset ;}

.left-foot-front{float: left;margin: 419px -39px;margin-bottom: unset ;}

.right-toe-front{float: left;margin: 434px -184px;margin-bottom: unset ;}

.left-toe-front{float: left;margin: 432px -30px; margin-bottom: unset ;}

.right-head-front{float: left;padding-left: 17px;}

.head-front{    padding-left: 9px;}

.head-back {margin-left: 12px;}

.left-head-back {float: left;margin-left: 20px;}/* .left-head-front{} */

@media(max-width: 1000px) {
	#MainDiv { padding: 0 16px 16px; max-width: 90%; margin: 35px auto; box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1); overflow: auto ;border-radius: 22px;}
	.container { max-width: 100%; }
	.form-group label {padding-left: 7px;margin-top: 10px;font-size: 20px;font-weight: 600;line-height: 20px;margin-bottom: 0;display: flex;justify-content: space-between;color: black;}
	.head{padding: 0%;margin-left: -33%;}
	.shoulderRegion{padding: 0%;margin-left: -88px;}
	  
	/* .right-head-front {float: left;padding-left: -41px;margin: 0px -89px;}
	.left-head-front {margin-left: -32px;} */
	.topheadfront {margin: 0px 0% 0px -33%;}
	.head-front {margin-left: -33.5%;}
	.jaw-front {margin-left: -25%;margin-top: 1px;float: left;}
	.right-wrist-front {margin-top: 134px;margin-left: -174%;}
	.right-hand-front {float: left;margin-left: -185%;margin-top: 19px;}
	.right-forearm-front {float: left;margin: 289px -310px;}
	.right-pelvis-front {margin-left: -263px;margin-top: 325px;}
	.left-pelvis-front {float: left;margin-left: -173px;margin-top: 121.5%;}
	.left-forearm-front {float: left;margin-left: -33px;margin-top: 279px;}
	.right-wrist-front {margin-top: 369px;margin-left: -141%;}
	.right-hip-front {float: left;margin: 21px -293px;}
	.left-hip-front {margin-left: -198px;float: left;margin-top: 21px;}
	.left-hand-front {float: right;margin-right: 94px;margin-top: 0px;}
	.left-wrist-front {float: left;margin-left: 106px;margin-top: 1px;}
	.left-thumb-front {float: right;margin-right: -73px;margin-top: 0px;}
	.left-fingers-front {float: right;margin: -108px 86px;}
	.right-hand-front {float: left;margin-left: -151%;margin-top: 41px;	}
	.right-thumb-front {float: left;margin: 41px -157% 0;}
	.right-fingers-front {float: left;margin: 30px -169px;}
	.right-upper-leg-front {margin: 25px -58px;float: left;}
	.left-upper-leg-front {margin: -28px 45px;float: left;}
	.lowerPartleg {margin: -91px 228px 0px 9px;float: right;}

	.left-head-back {float: left;margin-left: 15px;}
	.head-back{margin-left: -81px;}
	.b-cervical {
		margin-left: -67px;
	}
	.cervical-back{margin-left: -90px;}
	.left-shoulder-back {
		margin: 3px -90px;
		float: left;
	}
	.left-thoracic {
		float: left;
		margin: 3px -33px;
	}
	.right-thoracic {
		float: left;
		margin: 3px 33px;
	}
	.right-shoulder-back {
		float: left;
		margin: 3px -33px;
	}
	.right-upper-arm-back {
		margin: 83px -27px;
	}
	.right-ribs-back {
		margin: 83px -84px;
	}
	.central-thoracic {
		margin: 83px -136px;
	}
	.left-ribs-back {
		margin: 83px -193px;
	}
	.left-upper-arm-back {
		float: left;
		margin: 83px -246px;
	}
	.left-elbow-back {
		float: left;
		margin-left: -268px;
		margin-top: 161px;
	}
	.thoracolumbar {
		float: left;
		margin: 161px -192px;
	}
	.right-elbow-back {
		margin: 161px -28px;
	}
	.left-forearm-back {
		float: left;
		margin-top: 214px;
		margin-left: -305px;
	}
	.right-forearm-back {
		margin-top: 214px;
		margin-left: -33px;
	}
	.left-wrist-back {
		float: left;
		margin: 300px -376px;
	}
	.right-pelvis-back {
		float: left;
		margin: 268px -174px;
	}
	.right-wrist-back {
		float: right;
		margin-right: 95px;
		margin-top: 0px;
	}
	.left-hand-back {
		float: left;
		margin-left: -316px;
		margin-top: 37px;
	}
	.left-thumb-back {
		float: left;
		margin: 37px -348px;
	}
	.right-hip-back {
		margin-left: -133px;
		float: left;
		margin-top: 20px;
	}
	.left-hip-back {
		float: left;
		margin: 20px -225px;
	}
	.left-fingers-back {
		float: left;
		margin: 32px -346px;
	}
	.left-upper-leg-back {
		float: left;
		margin: 19px -236px;
	}
	.right-upper-leg-back {
		margin: 19px -132px;
		float: left;
	}

	.left-knee-back {
		float: left;
		margin: 239px -159px;
	}
	.right-knee-back {
		float: left;
		margin: 239px -56px;
	}
	.left-lower-leg-back {
		float: left;
		margin: 311px -163px;
		margin-bottom: 0px;
	}
	.right-lower-leg-back {
		float: left;
		margin: 311px -56px;
		margin-bottom: 0px;
	}
	.left-ankle-back {
		float: left;
		margin: 483px -133px;
		margin-bottom: 0px;
	}
	.left-foot-back {
		float: left;
		margin: 527px -132px;
		margin-bottom: 0px;
	}
	.left-toe-back {
		float: left;
		margin: 527px -157px;
		margin-bottom: 0px;
	}
	.right-ankle-back {
		float: left;
		margin: 485px -50px;
		margin-bottom: 0px;
	}
	.right-toe-back {
		float: left;
		margin: 528px -13px;
		margin-bottom: 0px;
	}
	.right-foot-back {
		float: left;
		margin: 529px -52px;
		margin-bottom: 0px;
	}


}

@media(max-width: 767px) {
	#MainDiv { padding: 0 16px 16px; max-width: 90%; margin: 35px auto; box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1); overflow: auto ;border-radius: 22px;}
	.form-group { margin-bottom: 25px; }
	#MainDiv .inner-content-wrapper { padding: 0 15px 30px; }
	.abc{padding-left: 7px;font-weight: 700;margin-top: 10px;font-size: 25px;line-height: 20px;margin-bottom: 0;
		display: flex;
		justify-content: space-between;
		color: #000;
	  }
	  
	/*.custom-radio + .custom-radio { margin-left: 2px; }*/
}

@media(max-width: 420px) {
	#MainDiv { padding: 0 16px 16px; max-width: 90%; margin: 35px auto; box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1); overflow: auto ;border-radius: 22px;}
	.custom-radio.rounded { display: flex; }
	.custom-radio.rounded + .custom-radio.rounded { margin: 16px 0 0; }
	.btn-block.prev-back-btn .btn { min-width: 120px; }
	.abc{
		padding-left: 7px;
		font-weight: 700;
		margin-top: 10px;
		font-size: 25px;
		line-height: 20px;
		margin-bottom: 0;
		display: flex;
		justify-content: space-between;
		color: #000;
	  }

	  
}


#homepageCards {
    padding: 0 16px 16px;
    max-width: 336px;
    margin: 35px auto;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
	border-radius: 22px;
	float: auto;
}

#titleCrds {
	position: absolute;
width: 337.28px;
height: 57.34px;
left: 402.09px;
top: 127px;

background: linear-gradient(89.14deg, #532C6D 7.77%, #64407B 91.15%);
border-radius: 12px;
transform: matrix(1, 0, 0, 1, 0, 0);
}

.todoList{
height: 57.34px;
}


.pleasework {
    position: relative;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
	transition: all 200ms ease;
}



.e-btn-icon.e-image {
	float: right;
    background: url(./UserIco.png);
    width: 29px;
	height: 29px;

}

.corner {
	border-radius: 100%;
  }